import {
  questionBankUrl,
  masterUrl
} from "../../js/path";

export default {
  data() {
    return {
      question_id: null,
      pageNo: null,
      filter: null,
      filterOn: [],
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      currentPage: 1,
      key: 0,
      fields: [{
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "question",
        },
        {
          key: "question_type",
          label: "Question Type",
        },
        {
          key: "options",
        },
        {
          key: "correct_option",
          lable: "Correct Option",
        },
        {
          key: "is_mandatory",
          label: "Is Mandatory",
        },
        {
          key: "edit",
        },
        {
          key: 'delete'
        }
      ],
      form: {
        question: "",
        question_type: "",
        correct_option: "",
        is_mandatory: 0,
        options: [{
          key: 0,
          value: "",
        }, ],
      },
      edit_image_name_url:null,
      image_name_url:null,
      image_name:null,
      questionTypes: [],
    };
  },
  methods: {
    addOption() {
      let length = this.form.options.length;
      this.form.options.push({
        key: length,
        value: "",
      });
    },
    removeOption(key) {
      if (key == 0) return;
      this.form.options.splice(key, 1);
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData('search');
        }
      } else if (this.filter.length == 0) this.fetchData('search');
    },
    searchFor() {
      if (this.filter.length > 0) this.fetchData("search");
      else if (this.filter.length == 0) this.fetchData("search");
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(pagination = null) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = questionBankUrl.questionBank;
        if (pagination == "search") {
          url = questionBankUrl.questionBank + "?search=" + this.filter;
        } else if (pagination) {
          url = url + "?page=" + pagination;
        }
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchQuestionTypes() {
      const url = masterUrl.getQuestionBankTypes;
      const data = await this.getRequest(url);
      if (data.status) {
        const responseData = data.response;
        this.questionTypes = responseData;
      }
    },
    async fetchQuestionBank(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = questionBankUrl.questionBank + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;

          this.question_id = responseData.id;

          if (responseData.question != null && responseData.question != "") {
            this.form.question = responseData.question;
          }

          if (responseData.question_type != null && responseData.question_type != "") {
            this.form.question_type = responseData.question_type;
          }

          if (responseData.options != "" && responseData.options != null) {
            this.form.options = JSON.parse(responseData.options);
          }

          if (responseData.correct_option != "" && responseData.correct_option != null) {
            this.form.correct_option = responseData.correct_option;
          }

          if (responseData.is_mandatory) {
            this.form.is_mandatory = 1;
          }
          this.edit_image_name_url = responseData.image_name;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteQuestionBank(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = questionBankUrl.questionBank + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$error) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields"
          });
          return false;
        }
        // console.log(this.$v.form.option[key]);
        // if (this.form.question_type == 'mcq' && this.$v.form.$model[key].value != "") {
        //   this.$store.commit("toast/updateStatus", {
        //     status: true,
        //     icon: "error",
        //     title: "Option Field is Required when Question Type is MCQ."
        //   });
        //   return false;
        // }
        // if (this.form.question_type == 'mcq' && this.form.options.length > 0 && this.form.correct_option != "") {
        //   this.$store.commit("toast/updateStatus", {
        //     status: true,
        //     icon: "error",
        //     title: "Correct Option is Required."
        //   });
        //   return false;
        // }
        this.$store.commit("loader/updateStatus", true);
        let url = questionBankUrl.questionBank;
        if (this.$route.name == 'edit-question-bank') {
          url = questionBankUrl.questionBank + '/' + this.question_id;
        }
        let dataAppend = new FormData();
        for (var key in this.form) {
          if (key != "options") {
            dataAppend.append(key, this.form[key]);
          }
        }
        if (this.form.is_mandatory) {
          dataAppend.append("is_mandatory", 1);
        } else {
          dataAppend.append("is_mandatory", 0);
        }
        dataAppend.append("options", JSON.stringify(this.form.options));
        if (this.image_name) {
          dataAppend.append('image_name', this.image_name);
        }
        if (this.$route.name == 'edit-question-bank') {
          dataAppend.append('_method', 'put');
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/question-bank");
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchExcel() {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = questionBankUrl.importFile;
        let dataAppend = new FormData();
        dataAppend.append("excel", this.$refs.file.files[0]);
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "File added successfully",
          });
          this.$refs.file.reset();
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    readFile(e, txt) {
      if (txt == 'image_name') {
        this.image_name = e.target.files[0];
        if (this.image_name?.size > 2 * 1000 * 1000) {
          this.$store.commit('toast/updateStatus', {
            status: true,
            icon: 'error',
            title: 'Uploaded File is more than 2MB'
          });
          this.$refs.image_name.reset();
          this.image_name_url = '';
          this.edit_image_name_url = '';
          this.image_name = '';
          return false
        } else {
          console.log(this.image_name?.size);
          this.image_name_url = URL.createObjectURL(this.image_name);
          this.edit_image_name_url = '';
        }
      }
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          this.params = `&page=${value}`;
          this.fetchData(value);
          this.pageNo = this.currentPage;
        }
      },
    },
    "form.is_mandatory"(v) {
      if (v == 1) {
        this.form.is_mandatory = true;
      } else {
        this.form.is_mandatory = false;
      }
    },
  },
  mounted() {
    if (
      this.$route.name == "add-question-bank" ||
      this.$route.name == "edit-question-bank"
    ) {
      this.fetchQuestionTypes();
      if (this.$route.name == "edit-question-bank") {
        this.fetchQuestionBank(this.$route.params.id);
      }
    } else {
      this.fetchData();
    }
  },
};