<template>
  <Layout>
    <template v-if="this.$route.name == 'add-question-bank'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="questionBank">
              <b-form-group id="input-group-1" class="position-relative">
                <label for="comm">Question <span style="color: red;">*</span></label>
                <b-form-input id="comm" v-model="form.question" placeholder="Enter Question" :class="{
                  'is-invalid': submitted && $v.form.question.$error,
                }"></b-form-input>
                <div v-if="submitted && !$v.form.question.required" class="invalid-feedback">
                  Question is required.
                </div>
                <!-- <div class="text-right">
                  <p v-if="form.question" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                    'badge-success': form.question.length !== 250,
                    'badge-danger': form.question.length === 250
                  }">
                    You typed
                    {{ form.question.length }} out of 250 chars.
                  </p>
                </div> -->
              </b-form-group>

              <b-form-group label="Question Banner Image" label-for="quiz-img">
                <b-form-file id="quiz-img" accept="image/*" @change="readFile($event, 'image_name')" ref="image_name"
                  placeholder="Choose a file [Upload Max File Size : 2MB]">
                </b-form-file>
                  <template v-if="$route.name == 'edit-question-bank' &&
                    edit_image_name_url
                    ">
                    <img :src="edit_image_name_url" width="168px" style="object-fit: contain; margin-top: 10px" />
                  </template>
                  <template v-if="image_name_url">
                    <img :src="image_name_url" width="168px" ref="image_name_url"
                      style="object-fit: contain; margin-top: 10px" />
                  </template>
              </b-form-group>

              <b-form-group>
                <label for="question_type">Question Type <span style="color: red;">*</span></label>
                <b-form-select id="question_type" v-model="form.question_type" :options="questionTypes"
                  value-field="value" text-field="name" :class="{
                    'is-invalid': submitted && $v.form.question_type.$error,
                  }">
                </b-form-select>
                <div v-if="submitted && !$v.form.question_type.required" class="invalid-feedback">
                  Question Type is required.
                </div>
              </b-form-group>

              <template v-if="form.question_type == 'mcq'">
                <div class="row" v-for="(val, index) in form.options" :key="index">
                  <div class="col-md-6">
                    <b-form-group id="input-group-1">
                      <label for="input-1">Option {{ index + 1 }} <span style="color: red">*</span></label>
                      <b-form-input id="input-1" v-model="val.value" :key="val.key" placeholder="Enter Option"
                        @keydown.space="preventLeadingSpace" :class="{
                          'is-invalid': submitted && $v.form.options.$error,
                        }"></b-form-input>
                      <!-- <div v-if="submitted && !$v.form.options.required" class="invalid-feedback">
                        Option is required.
                      </div> -->
                      <div v-if="(submitted && $v.form.$model.options[index].value != '')" class="invalid-feedback">
                        Option is required.
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4" style="margin-top: 30px">
                    <b-button variant="success" class="mr-3" v-if="((form.options.length - 1) == index)"
                      @click.prevent="addOption(index)" :disabled="val.value.length == 0">Add Option</b-button>
                    <b-button variant="danger" @click.prevent="removeOption(index)">Remove Option</b-button>
                  </div>
                </div>
              </template>

              <template v-if="form.question_type == 'mcq'">
                <b-form-group>
                  <label for="corr">Select Correct Option <span style="color: red">*</span></label>
                  <b-form-select id="corr" v-model="form.correct_option"
                    :options="form.options.filter(f => f.value !== '')" value-field="value" text-field="value">
                  </b-form-select>
                </b-form-group>
              </template>

              <b-form-group>
                <b-form-checkbox v-model="form.is_mandatory">Is Mandatory ?</b-form-checkbox>
              </b-form-group>

              <b-button type="submit" variant="primary" class="mr-2" @click.prevent="submitData"><span
                  v-if="this.$route.name == 'add-question-bank'">Save Data</span>
                <span v-else>Update Data</span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import questionBankMixin from "../../../mixins/ModuleJs/question-bank";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      questions: [""],
      submitted: false,
      title1: "Add Question Bank",
      title2: "Edit Question Bank",
      items: [
        {
          text: "Back",
          href: "/question-bank",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  mixins: [MixinRequest, questionBankMixin],
  components: {
    Layout,
    PageHeader,
  },
  validations: {
    form: {
      question: { required },
      question_type: { required },
      options: { required },
    },
  },
  methods: {
    preventLeadingSpace(e) {
      // only prevent the keypress if the value is blank
      if (!e.target.value) e.preventDefault();
      // otherwise, if the leading character is a space, remove all leading white-space
      else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, "");
    },
  },
};
</script>
